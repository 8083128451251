<template>
  <div>
    <div>
      {{ argument }}.
    </div>
  </div>
</template>

<script>
import jsMegaHal from '../jsMegaHal'
import brain from '../assets/brain.json'
import sourcetext from 'raw-loader!../assets/sourcetext.txt'


export default {
  name: 'ArgumentGenerator',
  data() {
    return {
      megahal: new jsMegaHal(4),
      argument: '',
      seed: (new Date().valueOf() * new Date().getMilliseconds()) % 4294967296
    }
  },
  methods: {
    exportMarkov(level) {
      let tmpmegahal = new jsMegaHal(level)
      tmpmegahal.addMass(sourcetext)
      console.log(JSON.stringify(tmpmegahal))

      var a = window.document.createElement('a')
      a.href = window.URL.createObjectURL(new Blob([JSON.stringify(tmpmegahal)], {type: 'application/json'}))
      a.download = 'brain.json'

      // Append anchor to body.
      document.body.appendChild(a)
      a.click();

      // Remove anchor from body
      document.body.removeChild(a)
    },
    getArgument() {
      return this.argument
    },
    reInitMegahal(level) {
      this.megahal = new jsMegaHal(level)
      this.megahal.addMass(sourcetext)
      this.newArgument()
    },
    newArgument() {
      let newSeed = (new Date().valueOf() * new Date().getMilliseconds()) % 4294967296
      this.megahal.reseed(newSeed)
      // Trigger reload. Maybe window.seed????
      window.seed = newSeed
      location.hash = '#' + newSeed
      this.argument = this.megahal.getReply()
    }
  },
  props: {
  },
  created: function() {
    let hashInt = parseInt(location.hash.slice(1))

    this.megahal.importBrain(brain)

    if (hashInt > 0) {
      this.megahal.reseed(hashInt)
    } else {
      location.hash = '#' + this.seed
    }

    this.argument = this.megahal.getReply()
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
