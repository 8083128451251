import { createApp } from 'vue'
import App from './App.vue'

import VueSocialSharing from 'vue-social-sharing'
import { ShareNetwork } from 'vue-social-sharing'

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* import specific icons */
import { faEarthAfrica, faRotate, faClipboard } from '@fortawesome/free-solid-svg-icons'
import { faFacebook, faTwitter } from '@fortawesome/free-brands-svg-icons'

/* add icons to the library */
library.add(faEarthAfrica, faRotate, faClipboard, faFacebook, faTwitter)

const app = createApp(App)
app.component('font-awesome-icon', FontAwesomeIcon)
app.component('ShareNetwork', ShareNetwork)
app.mount('#app')
app.use(VueSocialSharing)
