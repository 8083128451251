<template>
  <div class="content">
    <div class="left">
      <p class="username"><a href="./kaikkonen_big.png"><img class="profile" alt="Ylilääkäri Kaikkonen (Jopet-Show / YLE)" src="./assets/kaikkonen.png"></a><b>Itsenäinen Ajattelija</b> · <span @click="newArgument()" class="newargument"><font-awesome-icon icon="fa-solid fa-rotate" /> Uusi salaliitto</span></p>
      <p class="timestamp">Hetki sitten · <font-awesome-icon icon="fa-solid fa-earth-africa" /></p>
      <p class="argument"><ArgumentGenerator ref="generator" /></p>
      <p class="sharebuttons">
        <ShareNetwork
        network="facebook"
        :url="currentUrl"
        :title="currentArgument"
        description="Salaliittosimulaattori"
        :quote="currentArgument"
        hashtags="salaliittosimulaattori">
          <font-awesome-icon icon="fa-brands fa-facebook" /> Jaa<span class="desktoptext"> Facebookissa</span>
        </ShareNetwork>

        <ShareNetwork
        network="twitter"
        :url="currentUrl"
        :title="currentArgument"
        description="Salaliittosimulaattori"
        hashtags="salaliittosimulaattori">
          <font-awesome-icon icon="fa-brands fa-twitter" /> Jaa<span class="desktoptext"> Twitterissä</span>
        </ShareNetwork>

        <a class="copybutton" @clik="copyArgument()">
          <font-awesome-icon icon="fa-solid fa-clipboard" /> Kopioi<span class="desktoptext"> leikepöydälle</span>
        </a>
      </p>
    </div>
  </div>
  <div v-if="dev"><br/><br/><br/><br/><br/>
    <button @click="$refs.generator.exportMarkov(4)">Export Markov</button>
    <button @click="$refs.generator.reInitMegahal(0)">Taso 0</button>
    <button @click="$refs.generator.reInitMegahal(1)">Taso 1</button>
    <button @click="$refs.generator.reInitMegahal(2)">Taso 2</button>
    <button @click="$refs.generator.reInitMegahal(3)">Taso 3</button>
    <button @click="$refs.generator.reInitMegahal(4)">Taso 4</button>
    <button @click="$refs.generator.reInitMegahal(5)">Taso 5</button>
  </div>
</template>

<script>
import ArgumentGenerator from './components/ArgumentGenerator.vue'

export default {
  name: 'App',
  components: {
    ArgumentGenerator
  },
  data: function() {
    return {
      dev: true,
      counter: 0,
      mounted: false
    }
  },
  methods: {
    newArgument() {
      this.$refs.generator.newArgument()
      this.counter += 1;
    },
    copyArgument() {
      navigator.clipboard.writeText(this.$refs.generator.getArgument()).then(() => {

      })
      .catch(() => {

      })
    }
  },
  mounted() {
    this.mounted = true
  },
  computed: {
    currentUrl() {
      this.counter;
      return location.href
    },
    currentArgument() {
      this.counter;
      if (this.mounted) {
        return this.$refs.generator.getArgument()
      } else {
        return ""
      }
    }
  }
}
</script>

<style>
body {
  background-color: #E9EBEE;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

.newargument {
  border: none;
  background: white;
  cursor: pointer;
  color: blue;
  font-weight: bold;
}

.sharebuttons {
  margin-top: 0px;
  padding-top: 16px;
  width: 100%;
  border-top: 1px solid gray;
  display: block;
}

.copybutton, .share-network-facebook, .share-network-twitter {
  background: inherit;
  border: 0px;
  font-weight: bold;
  font-size: 15px;
  color: gray;
  text-align: center;
  cursor: pointer;
  margin-left: 1em;
  margin-right: 1em;
  text-decoration: none;
}

.desktoptext {
  display: none;
}

@media (min-width: 800px) {
  .content {
    width: 50%;
  }

  .desktoptext {
    display: inherit;
  }
}

.content {
  display: flex;
  margin-left: auto;
  margin-right: auto;
  background-color: white;
  border-radius: 15px;
}

.username {
  margin-bottom: 0px;
}

.timestamp {
  margin-top: 0px;
  color: gray;
}

.left {
  width: 100%;
  margin-top: 0px;
  margin-left: 15px;
  margin-right: 15px;
  float: left;
  text-align: left;
}

.argument {
  word-wrap: break-word;
  vertical-align:top;
}

.profile {
  border-radius: 50%;
  margin-right: 10px;
  float: left;
  width: 40px;
  height: 40px;
}
</style>
